@import "../../index.scss";

.root {
  z-index: 99999;

  .nav {
    width: 80%;
    margin: auto;
    height: 5rem;
    padding: 0;

    @include breakpoint("mobile") {
      margin: inherit;
    }

    &:first-child .link {
      padding-left: 0.5rem;
    }

    .link {
      cursor: grab;
      padding: 0 1rem;

      a {
        text-decoration: none;
        color: white;
      }

      &.bold {
        font-size: 20px;
      }
    }

    .logo {
      margin-top: 0.5rem;
      img {
        max-height: 2.5rem;
      }
    }

    .hidden {
      display: none;
    }
  }
}

.hiddenOnMobile {
  @include breakpoint("mobile") {
    display: none;
  }
}

.layout {
  width: 100%;
  height: calc(100vh - var(--offset));
  display: flex;
  flex-direction: column;
  padding-left: calc(4rem + 3rem);
  padding-right: 4rem;

  &.low {
    *:not(svg) {
      font-size: 14px;

      td {
        font-size: inherit;
        padding: 0 20px;
      }
    }
  }
  &.medium {
    *:not(svg) {
      font-size: 14px;

      td {
        font-size: inherit;
        padding: 2px 20px;
      }
    }
  }

  &.high {
    *:not(svg) {
      font-size: 18px;

      td {
        font-size: inherit;
        padding: 6px 20px;
      }
    }
  }

  &.hideNav {
    padding-left: 4rem !important;
  }

  &.expanded {
    padding-left: calc(105px + 3rem);
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  padding-right: 4rem;
  padding-left: calc(3rem + 4rem);

  &.nav {
    padding-left: 4rem;
  }

  &.hideNav {
    padding-right: 0;
    padding-left: 0;
  }

  &.black {
    background-color: black;
    color: white;
  }

  &.expanded {
    width: 100%;
    padding-left: calc(105px + 3rem);

    &.nav {
      padding-left: 105px;
    }
  }

  &.sticky {
    background: white;
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
}

.container {
  &.expanded {
    width: calc(100% - 4rem);
    padding-left: 105px;
  }

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  height: 6rem;
}
