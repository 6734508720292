$breakpoints: (
  "mobile": 540px,
  "tablet": 768px,
  "desktop": 1024px,
) !default;

@mixin breakpoint($breakpoint) {
  @media (max-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }

  @media (max-height: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}
