.row {
  display: table-row;

  &:hover {
    td {
      background-color: #f3f3f3 !important;
    }
  }

  &.clickable {
    &:hover {
      cursor: grab;
    }
  }

  &.highlighted {
    background-color: #f3f3f3 !important;
  }
}
