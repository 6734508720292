.title {
  color: var(--title-color);
}

.modal {
  &.low {
    *:not(svg, h2) {
      font-size: 14px;

      td {
        font-size: inherit;
        padding: 0 20px;
      }
    }
  }
  &.medium {
    *:not(svg, h2) {
      font-size: 14px;

      td {
        font-size: inherit;
        padding: 2px 20px;
      }
    }
  }

  &.high {
    *:not(svg, h2) {
      font-size: 20px;

      td {
        font-size: inherit;
        padding: 6px 20px;
      }
    }
  }
}
