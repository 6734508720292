@import "./index.scss";

.mobile {
  display: none;

  @include breakpoint("mobile") {
    display: flex;
    flex-direction: column;
    overflow: clip;
    width: 100vw;
    height: 100vh;
  }
}

.nonmobile {
  display: inherit;

  @include breakpoint("mobile") {
    display: none;
  }
}
